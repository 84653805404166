import Axios from 'axios';
import {mask} from 'vue-the-mask';
import moment from 'moment';

import Vue from 'vue'
import TextareaAutosize from 'vue-textarea-autosize'

Vue.use(TextareaAutosize);

export default {
  directives: {mask},
  data() {
    return {
      TOKEN: this.$store.state.token,
      me: this.$store.state.me,
      form: {
        profile: {
          country: {},
          state: {},
          city: {},
        },
        network: {},
        financial: {
          razao: "",
          bank_id: 1
        },
        legal: {
          have_cnpj: 1,
        },
        main: {},
        specialities: [],
      },
      formPassword: '',
      errors: {
        avatar: [],
        specialities: [],
        experience: [],
        cpf: "",
        cnpj: "",
      },
      countries: [],
      states: [],
      cities: [],
      banks: [],
      categories: [],
      specialities: [],
      bankInput: '',
      selectedBank: '',
      avatarFile: '',
      profile_status: '',
      feedback: '',
      collapse: {
        one: true, //
        two: false,
        three: false,
        four: false,
        five: false,
        six: false,
      },
      syncing: false,
      tags: {
        search: '',
        searchComplementary: '',
        searchExperience: '',
        visibility: {
          main: true,
          specialities: true,
        }
      }
    }
  },
  mounted() {

    jQuery(document).on("input", "#bio", function() {
      var limite = 185;
      var caracteresDigitados = jQuery(this).val().length;
      var caracteresRestantes = limite - caracteresDigitados;

      if (caracteresRestantes <= 0) {
        var comentario = jQuery("textarea[name=bio]").val();
        jQuery("textarea[name=bio]").val(comentario.substr(0, limite));
        jQuery(".caracteres").text("(0)");
      } else {
        jQuery(".caracteres").text("- (" + caracteresRestantes + ")");
      }
      if ( jQuery("textarea[name=bio]").val() == "") {
        jQuery(".caracteres").text("");
      }
    });

   // jQuery('#bio').bind('copy paste', function (e) {
   //    e.preventDefault();
   // });

    // document.getElementById("bio").onkeypress = function (event) {
    //   if (event.keyCode == 13) {
    //     event.preventDefault();
    //   }
    // };

    var _this = this;

    if (_this.$store.state.role == 'admin') _this.$router.push({ path: '/dashboard/admin' });
    if (_this.$store.state.role == 'client') _this.$router.push({ path: '/dashboard/client' });

    axios
    .get('/api/v1/users/' + this.me.id, {
      headers: {
        Authorization: 'Bearer ' + this.TOKEN,
      }
    })
    .then(response => {
      if (!response.data.error) {
        if (!response.data.data.financial) response.data.data.financial = {};
        response.data.data.main = {};
        this.form = response.data.data;
        this.form.password = '';
        let index = this.form.specialities.map(function(e) { return e.pt; }).indexOf(this.form.profile.major);
        if (index > -1) {
          this.form.main = this.form.specialities[index];
          this.form.specialities.splice(index, 1);
        }
        this.form.profile.nascimento = moment(this.form.profile.nascimento).format('YYYY-MM-DD');

        if (this.form.main.id) this.tags.visibility.main = false;
        if (this.form.specialities.length) this.tags.visibility.specialities = false;
        this.profile_status = response.data.data.profile_status
      }
    });

    axios
    .get('/api/v1/specialities', {
      headers: {
        Authorization: 'Bearer ' + this.TOKEN,
      }
    })
    .then(response => {
      if (!response.data.error) {
        this.specialities = response.data.data;
      }
    });

    axios
    .get('/api/v1/categories', {
      headers: {
        Authorization: 'Bearer ' + this.TOKEN,
      }
    })
    .then(response => {
      if (!response.data.error) {
        for (let i = 0; i < response.data.data.length; i++) {
          const category = response.data.data[i];
          category.show = false;
        }
        this.categories = response.data.data;
        
      }
    });

    axios
    .get('/api/v1/banks', {
      headers: {
        Authorization: 'Bearer ' + this.TOKEN,
      }
    })
    .then(response => {
      if (!response.data.error) {
        this.banks = response.data.data;
        let index = this.banks.map(function(e) { return e.id; }).indexOf(this.form.financial.bank_id);
        if (index > -1) this.bankInput = this.banks[index].name;
      }
    });

    axios
    .get('/api/v1/countries', {
      headers: {
        Authorization: 'Bearer ' + this.TOKEN,
      }
    })
    .then(response => {
      if (!response.data.error) {
        this.countries = response.data.data;
        this.countries.unshift({"id":3,"code":"BR","phone":"55","name":"Brasil"});
      }
    });

    axios
    .get('/api/v1/states', {
      headers: {
        Authorization: 'Bearer ' + this.TOKEN,
      }
    })
    .then(response => {
      if (!response.data.error) {
        this.states = response.data.data;
        this.states.unshift({"id": 26,"code": "SP","name": "São Paulo","country_id": 3});
      }
    });

    axios
    .get('/api/v1/cities', {
      headers: {
        Authorization: 'Bearer ' + this.TOKEN,
      }
    })
    .then(response => {
      if (!response.data.error) {
        this.cities = response.data.data;
        this.cities.unshift({"id": 5034,"name": "São Paulo","state_id": 26});
      }
    });

  },
  methods: {

    chooseSpeciality(speciality) {
      let double = this.form.specialities.map(function (e) { return e.pt; }).indexOf(speciality.pt);
      if (double < 0 && this.form.main.id != speciality.id) {
        this.form.main = speciality;
      }
    },

    chooseSpecialityComplementary(speciality) {
      let double = this.form.specialities.map(function (e) { return e.pt; }).indexOf(speciality.pt);
      // não está ainda na lista selecionada de habilidades
      if (double < 0 && this.form.main.id != speciality.id) {
        if (this.form.specialities.length < 5) this.form.specialities.push(speciality);
      } else {
        this.removeSpeciality(speciality);
      }
    },

    chooseExperience(event) {
      let pt = this.tags.searchExperience;
      let speciality = this.specialities.find(o => o.pt == pt);
      let double = this.form.specialities.map(function (e) { return e.pt; }).indexOf(speciality.pt);
      // não está ainda na lista selecionada de habilidades
      if (double < 0) {
        if (this.form.specialities.length < 6) this.form.specialities.push(speciality);
      } else {
        this.removeSpeciality(speciality);
      }
      this.tags.searchExperience = '';
    },

    removeMain(s) {
      // remove habilidade main do briefing
      this.form.main = {};
      this.tags.visibility.main = true;
      this.tags.visibility.specialities = false;
    },

    removeSpeciality(s) {
      // remove habilidade do briefing
      let index = this.form.specialities.map(function (e) { return e.pt; }).indexOf(s.pt);
      if (index > -1) {
        this.form.specialities.splice(index, 1);
        if (s.category_id != 6 && s.category_id != 15) this.tags.visibility.specialities = true;
      }
    },

    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },

    validateMainSpeciality(e) {
      let text = this.mainSpecialityInput;
      let index = this.specialities.map(function(e) { return e.pt; }).indexOf(text);
      // faz parte da lista de habilidades
      if (index > -1) {
        this.form.profile.major = text;
      }
      this.mainSpecialityInput = '';
    },

    validateMainBank(e) {
      let text = this.mainBankInput;
      this.mainBankInput = '';
    },

    validateListComplementares(e) {
      if (this.form.specialities.length < 5) {
        let text = this.specialitiesInput;
        let index = this.specialities.map(function(e) { return e.pt; }).indexOf(text);
        let double = this.form.specialities.indexOf(text);
        // faz parte da lista de habilidades
        if (index > -1) {
          // não está ainda na lista selecionada de habilidades
          if (double < 0) this.form.specialities.push(this.specialities[index]);
        }
      }
      this.specialitiesInput = '';
    },

    setBankId(e) {
      let index = this.banks.map(function(e) { return e.name; }).indexOf(this.bankInput);
      if (index > -1) this.selectedBank = this.banks[index].id;
    },

    handleAvatarUpload() {
      let size = this.$refs.avatar.files[0].size / 1024 / 1024;
      if (size > 2) this.errors.avatar.push("O arquivo é muito grande, reduza a imagem para menos de 2MB.");
      else this.errors.avatar = [];
      this.avatarFile = this.$refs.avatar.files[0];
    },

    submit() {
      let _this = this;
      _this.syncing = true;
      let data = {
        specialities: [],
      };
      var formData = new FormData();
      let form = _this.form;
      // Habilidades
      if (form.specialities.length < 2) {
        _this.errors.specialities.push("Escolha no mínimo 2 habilidades.");
        _this.syncing = false;
        return;
      }
      else _this.errors.specialities = [];
      
      // Criadores obriga um aspecto pessoal
      // if (form.type_id == 2 && form.specialities.filter(s => s.category_id == 12).length < 1) {
      //   _this.errors.specialities.push("Escolha pelo menos uma tag pessoal.");
      //   _this.syncing = false;
      //   return;
      // }
      // else _this.errors.specialities = [];

      form.specialities.forEach(e => {
        data.specialities.push(e.id);
      });
      data.specialities = data.specialities.join(';');
      formData.set("specialities", data.specialities);
      if (form.main.id) {
        formData.set("mainspeciality", form.main.pt);
      } else {
        alert('Habilidade principal Inválida - Devido mudanças em nosso banco de dados por favor escolha uma nova habilidade principal.');
        _this.syncing = false;
        return;
      }

      if (form.profile.cpf && !this.validCPF(form.profile.cpf)) {
        _this.errors.cpf = "Verifique seu CPF.";
        _this.syncing = false;
        return;
      }

      if (form.legal.cnpj && !this.validCNPJ(form.legal.cnpj)) {
        _this.errors.cnpj = "Verifique seu CNPJ.";
        _this.syncing = false;
        return;
      }

      // Links
      if (form.network.linkedin != null) formData.set("linkedin", form.network.linkedin);
      else formData.set("linkedin", '');
      if (form.network.instagram != null) formData.set("instagram", form.network.instagram);
      else formData.set("instagram", '');
      if (form.network.behance != null) formData.set("behance", form.network.behance);
      else formData.set("behance", '');
      if (form.network.youtube != null) formData.set("youtube", form.network.youtube);
      else formData.set("youtube", '');
      if (form.network.vimeo != null) formData.set("vimeo", form.network.vimeo);
      else formData.set("vimeo", '');
      if (form.network.tiktok != null) formData.set("tiktok", form.network.tiktok);
      else formData.set("tiktok", '');
      if (form.network.twitch != null) formData.set("twitch", form.network.twitch);
      else formData.set("twitch", '');

      // curator code
      if (form.profile.curator_code != null) formData.set("curator_code", form.profile.curator_code);

      if (form.profile.site != null) {
        // valida http/https
        if (form.profile.site.substr(0, 4) !== 'http') {
          form.profile.site = 'https://' + form.profile.site;
        }
        formData.set("site", form.profile.site);
      }
      else formData.set("site", '');
      // Perfil
      if (form.profile.bio != null) formData.set("bio", form.profile.bio);
      else formData.set("bio", '');
      // Dados pessoais
      if (form.name != null) formData.set("name", form.name);
      if (form.last_name != null) formData.set("last_name", form.last_name); else formData.set("last_name", '');
      if (form.type_id != null) formData.set("type_id", form.type_id);
      if (form.profile.perfil != null) formData.set("perfil", form.profile.perfil); else formData.set("perfil", '');
      if (form.profile.rg != null) formData.set("rg", form.profile.rg); else formData.set("rg", '');
      if (form.profile.cpf != null) formData.set("cpf", form.profile.cpf); else formData.set("cpf", '');
      if (form.profile.phone != null) formData.set("phone", form.profile.phone);
      let validdate = /\d+-\d+-\d+/.test(form.profile.nascimento);
      if (validdate) formData.set("born_date", form.profile.nascimento);
      if (form.profile.country_id) formData.set("country", form.profile.country_id);
      data.state = form.profile.country_id == 3 ? form.profile.state_id : form.profile.state_open;
      if (data.state) formData.set("state", data.state);
      data.city = form.profile.country_id == 3 ? form.profile.city_id : form.profile.city_open;
      if (data.city) formData.set("city", data.city);
      // Dados bancários
      if (form.legal.cnpj != null) formData.set("cnpj", form.legal.cnpj);
      else formData.set("cnpj", '');
      if (form.financial.bank_id != null) formData.set("bank", form.financial.bank_id);
      if (form.financial.type != null) formData.set("type", form.financial.type);
      else formData.set("type", '');
      if (form.financial.razao != null) formData.set("razao", form.financial.razao);
      else formData.set("razao", '');
      if (form.financial.agency != null) formData.set("agency", form.financial.agency);
      else formData.set("agency", '');
      if (form.financial.account != null) formData.set("account", form.financial.account);
      else formData.set("account", '');
      formData.set("currency", _this.me.currency);
      // Login Creators
      if (form.email && form.email != _this.me.email) formData.set("email", form.email);
      if (_this.formPassword && _this.formPassword.length) formData.set("password", _this.formPassword);
      if (this.avatarFile) formData.append('avatar', this.avatarFile);

      formData.append('_method', 'PUT');
      axios
        .post('/api/v1/users/' + _this.me.id, formData, {
          headers: {
            Authorization: 'Bearer ' + _this.TOKEN,
            'Content-Type': 'multipart/form-data',
          }
        })
        .then(response => {
          console.log('retorno', response.data);
          if (response.data.error) {
            _this.errors = response.data.error;
            _this.syncing = false;
          } else {
            _this.$store.commit('setMe', response.data.data);
            _this.$router.push({ path: '/dashboard/professional' });
          }
      });
    },

    logout() {
      // unset variables
      this.$store.commit('setToken', '');
      this.$store.commit('setMe', '');
      this.$router.push({ path: '/' });
    },

    deleteAccount() {
      axios
      .delete('/api/v1/users/' + this.me.id, {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        if (response.data.error) {
          this.errors = response.data.error;
        } else {
          $('#delete-account-modal').modal('hide');
          this.$store.commit('setToken', '');
          this.$store.commit('setMe', '');
          this.$router.push({ path: '/' });
        }
      });
    },

    //Show Password
    showPassword() {
      var x = document.getElementById("password");
      jQuery('.ico-eye').toggleClass('show');
      if (x.type === "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
    },

    handleCollapse(index) {
      switch (index) {
        case 1:
          this.collapse.one = !this.collapse.one;
          this.collapse.two = this.collapse.three = this.collapse.four = this.collapse.five = this.collapse.six = false;
          break;
        case 2:
          this.collapse.two = !this.collapse.two;
          this.collapse.one = this.collapse.three = this.collapse.four = this.collapse.five = this.collapse.six = false;
          break;
        case 3:
          this.collapse.three = !this.collapse.three;
          this.collapse.one = this.collapse.two = this.collapse.four = this.collapse.five = this.collapse.six = false;
          break;
        case 4:
          this.collapse.four = !this.collapse.four;
          this.collapse.one = this.collapse.two = this.collapse.three = this.collapse.five = this.collapse.six = false;
          break;
        case 5:
          this.collapse.five = !this.collapse.five;
          this.collapse.one = this.collapse.two = this.collapse.three = this.collapse.four = this.collapse.six = false;
          break;
        case 6:
          this.collapse.six = !this.collapse.six;
          this.collapse.one = this.collapse.two = this.collapse.three = this.collapse.four = this.collapse.five = false;
          break;
        default:
          this.collapse.one = this.collapse.two = this.collapse.three = this.collapse.four = this.collapse.five = this.collapse.six = false;
          break;
      }
    },

    checkCPF() {
      console.log("checkCPF");
      if (this.validCPF(this.form.profile.cpf)) {
        this.errors.cpf = "";
      } else {
        this.errors.cpf = "Verifique seu CPF.";
      }
    },

    checkCNPJ() {
      console.log("checkCNPJ");
      if (this.validCNPJ(this.form.legal.cnpj)) {
        this.errors.cnpj = "";
      } else {
        this.errors.cnpj = "Verifique seu CNPJ.";
      }
    },

    validCPF(cpf) {
      cpf = cpf.replace(/[\.\-\/]/g, '');
      console.log(cpf);
      if (!cpf || cpf.length != 11) return false;
      var soma = 0;
      var resto;
      for (var i = 1; i <= 9; i++)
        soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
      resto = (soma * 10) % 11
      if ((resto == 10) || (resto == 11)) resto = 0
      if (resto != parseInt(cpf.substring(9, 10)) ) return false;
      soma = 0
      for (var i = 1; i <= 10; i++)
        soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i)
      resto = (soma * 10) % 11
      if ((resto == 10) || (resto == 11))  resto = 0
      if (resto != parseInt(cpf.substring(10, 11) ) ) return false;
      return true;
    },

    validCNPJ(cnpj) {
      cnpj = cnpj.replace(/[^\d]+/g,'');

      console.log(cnpj);

      if (cnpj == '') return false;

      if (cnpj.length != 14) return false;

      // Elimina CNPJs invalidos conhecidos
      if (cnpj == "00000000000000" ||
          cnpj == "11111111111111" ||
          cnpj == "22222222222222" ||
          cnpj == "33333333333333" ||
          cnpj == "44444444444444" ||
          cnpj == "55555555555555" ||
          cnpj == "66666666666666" ||
          cnpj == "77777777777777" ||
          cnpj == "88888888888888" ||
          cnpj == "99999999999999")
          return false;

      // Valida DVs
      let i;
      let tamanho = cnpj.length - 2
      let numeros = cnpj.substring(0, tamanho);
      let digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
              pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(0)) return false;

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
              pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(1))
            return false;

      return true;
    },

    changeStatusProfile(){
      var status
      if(this.profile_status == false){
        this.profile_status = 1
      } else if( this.profile_status = true){
        this.profile_status = 0
      }

      let data = {
        profile_status: this.profile_status
      };
      axios
        .put('/api/v1/users/' + this.me.id + '/change-status-profile', data, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN
          }
        })
        .then(response => {
          if (!response.data.error) {
            if(this.profile_status == true){
              status = 'Ativo'
            } else{
              status = 'Inativo'
            }
          this.feedback = 'Seu perfil está ' + status
            setTimeout(() => {
              this.feedback = ''
            }, "2000")
          } else {
            console.log(response.data.error);
          }
      });
    },
  },

  computed: {

    avatar() {
      return (this.form.avatar) ? '/' + this.form.avatar : '/images/avatar.jpg';
    },

    type() {
      return this.form.type_id;
    },

    filteredCategories() {
      if (this.tags.search && this.tags.search != "") {
        return this.categories.map(category => {
          category.filteredSpecialities = category.specialities
          .filter(speciality => {
            return speciality.pt.toLowerCase().indexOf(this.tags.search.toLowerCase()) > -1;
          })
          return category;
        });

      } else {
        for (var i = 0; i < this.categories.length; i++) {
          this.categories[i].filteredSpecialities = this.categories[i].specialities;
        }
        return this.categories;
      }
    },

    filteredComplementary() {
      if (this.tags.searchComplementary && this.tags.searchComplementary != "") {
        return this.categories.filter(cat => {
          if (this.form.type_id == 1) return (cat.id != 6 || cat.id != 10 || cat.id != 11 || cat.id != 12);
          if (this.form.type_id == 2) return (cat.id == 10 || cat.id == 11 || cat.id == 12);
        }).map(category => {
          category.filteredSpecialitiesComplementary = category.specialities
          .filter(speciality => {
            return speciality.pt.toLowerCase().indexOf(this.tags.searchComplementary.toLowerCase()) > -1;
          })
          return category;
        });

      } else {
        for (var i = 0; i < this.categories.length; i++) {
          this.categories[i].filteredSpecialitiesComplementary = this.categories[i].specialities;
        }
        return this.categories.filter(cat => {
          if (this.form.type_id == 1) return (cat.id != 6 || cat.id != 10 || cat.id != 11 || cat.id != 12);
          if (this.form.type_id == 2) return (cat.id == 10 || cat.id == 11 || cat.id == 12);
        });
      }
    },

  },

  watch: {

    type() {
      if (this.form.type_id == 2 && this.specialities.length > 1) {
        let creator = this.specialities.filter(s => s.id == 98);
        this.form.main = creator[0];
      }
    }

  }

}
